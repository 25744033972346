.image-box {
    background: #FFFFFF;
    // box-shadow: 0px 1px 4px rgba(63, 63, 68, 0.15),
    //     0px 0px 0px rgba(63, 63, 68, 0.05);
    // border-radius: 3px;
    display: flex;
    flex-direction: column;
    padding: 32px;
    width: 50%;

}

.image-data {
    // border-radius: 50%;
    // width: 50px;
    // height: 50px;
    // margin-right: 24px;
    // flex-shrink: 0
}


@media(max-width:1000px) {
    .image-box {
        width: 100%;
        padding: 0 16px;
        // width: 85%;
        // padding: 16px
    }

}
.TabContainer {

    display: flex;
    flex-direction: column;
    width: 100%;
}

.TabsHeader {
    width: 100%;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* border-top: 1px solid #E5E5E5; */
    border-bottom: 1px solid #E5E5E5;
}

.SubTabsHeader {
    width: 100%;
    display: flex;
    font-size: 14px;
    font-weight: 500;
     /* border-top: 1px solid #E5E5E5; */


}

.Tab {
    display: flex;
    align-items: center;
    justify-content: center;
/* identical to box height */
    font-weight: 500;  
    letter-spacing: 1px;
    /* text-transform: uppercase; */
    padding:16px 8px;
    color: rgba(0, 0, 0, 0.6);
    width: 25%;
    cursor: pointer;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #637381;
    /* min-width: 100px;
    max-width: auto; */
}

.Tab.Selected {
    color: rgba(0, 0, 0, 0.87);
    border-bottom:  2px solid #009AE0;
    /* min-width: 100px; */
    /* max-width: auto; */
}

.emptyStateDivision {
    height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10%;
}

.emptyState_text {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    padding: 24px 0;
}

.emptyState_subtext {
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;

    color: rgba(0, 0, 0, 0.6);
}

.emptyState_button {
    border: 1px solid #009AE0;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.emptyState_button--text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    padding: 8px 32px;
    color: #009AE0;
}

.borderBottom {
    border-bottom: 1px solid #E5E5E5;
}

@media(max-width:340px){
    .Tab{
        font-size:10px;
    }

}


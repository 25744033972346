.form {
    margin: 1rem 0;
    @include flexColumn;
    //  width: 40rem;


    @include respond(tab-land) {
        //  width: 38rem;
    }

    @include respond(medium-screen) {
        //    width: 34rem;
    }

    @include respond(tab-port) {
        //   width: 31rem;
    }


    @include respond(phone) {
        min-width: 100%;
        //  width: 40rem;
    }

    &__input {
        color: #212B36;
        margin: 1rem 0;
        //background: #D8D8D8;
        border: 1px solid #979797;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: $font-size-small;
        padding: 1.4rem 1.6rem;
        color: #999999;
        //position: relative;

        &--2 {

            width: 48%;
            left: 0;
        }
    }


    &__button {
        margin-top: 2.4rem;
    }
}

.select-css {
    display: block;
    padding: 1.4rem 1.6rem;
    border-radius: 4px;
    font-size: $font-size-small;
    color: #999999;
    border: 1px solid #979797;

    width: 50%;
    max-width: 50%;

    margin: 1rem 0 1rem 0.8rem;



    appearance: none;
    background-color: #fff;

}

.select-css::-ms-expand {
    display: none;
}

.select-css:hover {}

.select-css:focus {

    outline: none;
}

.select-css option {
    font-weight: normal;
}



.successPopup {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
    //background: rgba(0, 0, 0, 0.4);
    @include flexCenter;
}

.successPopup__content {
    background-color: #009ae0;
    padding: 3rem 6rem;
    border-radius: 5px;
    width: 30%;
    text-align: center;

    @include respond(phone) {
        width: 80%;
    }
}



@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        z-index: 1000;
    }

    // 10% {
    //     opacity: 0.9;
    // }

    // 20% {
    //     opacity: 0.8;
    // }


    // 30% {
    //     opacity: 0.7;
    // }

    // 40% {
    //     opacity: 0.6;
    // }

    // 50% {
    //     opacity: 0.5;


    // }

    // 60% {
    //     opacity: 0.4;
    // }

    // 70% {
    //     opacity: 0.3;
    // }

    // 80% {
    //     opacity: 0.2;
    // }

    90% {
        opacity: 0.1;
    }

    100% {
        opacity: 0;
        display: none;
        z-index: 0;
        visibility: hidden;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 1000;

    }

    // 10% {
    //     opacity: 0.9;
    // }

    // 20% {
    //     opacity: 0.8;
    // }


    // 30% {
    //     opacity: 0.7;
    // }

    // 40% {
    //     opacity: 0.6;
    // }

    // 50% {
    //     opacity: 0.5;


    // }

    // 60% {
    //     opacity: 0.4;
    // }

    // 70% {
    //     opacity: 0.3;
    // }

    // 80% {
    //     opacity: 0.2;
    // }

    90% {
        opacity: 0.1;
    }

    100% {
        opacity: 0;
        display: none;
        z-index: 0;
        visibility: hidden;
    }

}

input {
    outline: none;
    color: #212B36;

}

.successOut {
    //  display: block;
    -webkit-animation: fadeOut 4s;
    animation: fadeOut 4s;
    animation-fill-mode: forwards;
}

.whatsappBtn {
    background: #25D366;
    border-radius: 3px;
    padding: 11px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    width: 100%;
    cursor: pointer;
    color: #FFFFFF;

}

.whatsappAnchor {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    // padding: 0 4px;
}

.whatsappAnchor:hover {
    color: #FFFFFF;
}

.spacing {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.yourNumber-input {
    width: 100%;
    padding-left: 60px;
    color: #212B36;

}


.indiaFlag {
    position: absolute;
    left: 10px;
    bottom: 18px;

}

.form-Bottom {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 106px 0 0 0;
}

.phone-line {
    position: absolute;
    left: 50px;
    top: 20px;
}

@media(max-width:1000px) {
    .phone-line {
        top: 12px;

    }

    .indiaFlag {
        position: absolute;
        left: 10px;
        bottom: 13px;
    }
}
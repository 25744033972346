.testi-box {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(63, 63, 68, 0.15),
        0px 0px 0px rgba(63, 63, 68, 0.05);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    padding: 32px;
    width: 50%;

}

.testi-head {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #515978;
    text-align: left;
    margin: 0;

}

.testi-colen {
    width: 24px;
    // margin: 0 0 16px 0;
}

.testi-content {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6B7177;
    margin: 16px 0 32px 0;
    text-align: left;
}

.testi-image-div {
    display: flex;


}

.testi-name {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #515978;
    margin-bottom: 4px;
    text-align: left;
    width: 100%;

}

.testi-institute {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #6B7177;
    text-align: left;
    width: 100%;

}

.testi-image {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 24px;
    flex-shrink: 0
}

.major-div {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.major-heading {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 38px;
    text-align: center;

    color: #212326;

}

.major-subheading {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 24px;
    color: #6B7177;

}

@media(max-width:1000px) {
    .testi-box {
        width: 85%;

        padding: 16px
    }

    .testi-head {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
    }

    .testi-content {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }

    .testi-name {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
    }

    .testi-institute {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 24px;
    }
}
.frequent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.frequent-heading {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #212326;
    margin: 8px 0;
    padding: 0 8px;
}

.frequent-subheading {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #6B7177;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
}

.frequent-grid {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(63, 63, 68, 0.15),
        0px 0px 0px rgba(63, 63, 68, 0.05);
    border-radius: 3px;
    width: 80%;
    margin: 60px auto 32px auto;

}

.frequent-grid-item {
    display: flex;
    flex-direction: column;
    padding: 24px;


}

.frequent-grid-item-head {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #212326;

}

.frequent-grid-item-content {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6B7177;

}

.see-more-div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0 100px 0;

}

.see-more {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    border: 1px solid #009AE0;
    border-radius: 4px;
    color: #009AE0;
    display: flex;
    justify-content: center;
    padding: 12px 24px;
    background: #FFFFFF;
    cursor: pointer;
}


@media(max-width:1000px) {
    .frequent-grid {
        grid-template-columns: repeat(1, 100%);
        width: 90%;
        margin: 0 auto;

    }
}
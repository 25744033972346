.btn {
    @include btn;
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;

    &--bookADemo {

        padding: 16px;
        font-size: $font-size-small;
        border-radius: 4px;
        background-color: $color-primary-blue;
        color: $color-primary-white;
        outline: none;
        border: none;

        cursor: pointer;

        @include respond(phone) {
            padding: 1.4rem 1.6rem;
        }
    }
}

.btn:hover {
    -webkit-transform: translateY(-0.2rem);
    -ms-transform: translateY(-0.2rem);
    transform: translateY(-0.2rem);
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

button {
    outline: none;
    border: none;
}
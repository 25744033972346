.container {
    display: flex;
    flex-direction: column;
    padding: 80px 0 0 0;
    background: #ffffff
}

.firstBox {
    display: flex;
    flex-direction: column;
    // padding: 0 24px;

}

.firstBox-heading {
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;
    padding: 0 24px 16px 24px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
}

.firstBox-subheading {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    margin: 0 24px 32px 24px;
    color: #6B7177;
    // color: #00A7F3;
}

.whatsapp-button {
    background: #25D366;
    border-radius: 3px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    outline: none;
    border: none;
    padding: 12px 0;
    margin: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.firstBox-secondHeading {
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    padding: 40px 24px 16px 24px;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    margin: 0px;

}

.firstBox-secondSubheading {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    padding: 0 24px 40px 24px;
    text-align: center;
    margin: 0px;
    color: #00A8F4;
}

.firstBox-text {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.87);
    padding: 40px 24px;
    text-align: center;
}


.coverImage {
    height: 260px
}


.secondBox {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: #FBF7ED;
}

.secondBox-heading {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: rgba(0, 0, 0, 0.6);
    margin: 0px;


}

.secondBox-subheading {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    padding: 24px 0 16px 0;
    color: rgba(0, 0, 0, 0.87);
    margin: 0px;
}

.secondBox-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    margin: 0px;
    color: rgba(0, 0, 0, 0.6);
}

.thirdBox {
    padding: 32px 24px 0 24px;
    display: flex;
    flex-direction: column;
    // justify-content: flex-start;
    // align-items: center;

}

.thirdBox-item {
    display: flex;
    padding-bottom: 40px;

}

.thirdBox-left {}

.thirdBox-right {
    padding-left: 24px;
    display: flex;
    flex-direction: column;

}

.thirdBox-right-heading {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 24px;
    margin: 0;
    padding: 0 0 8px 0;
    color: #212326;

}

.thirdBox-right-subheading {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
    color: #6B7177;
}


.button-background {
    background: #FFFFFF;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.24);
    padding: 8px 16px;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.button-fixed {

    background: #25D366;
    border-radius: 3px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    outline: none;
    border: none;
    padding: 12px 0;
    // margin: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

@media(max-width:360px) {
    .coverImage {
        height: 230px
    }
}

@media(max-width:340px) {
    .coverImage {
        height: 210px
    }
}
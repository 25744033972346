.seemore {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    background: #ffffff;
    flex-direction: column;
}

.seemore-first {
    width: 100%;
    background: #F6F9FC;
    padding: 60px 7% 40px 7%;

}

.seemore-head {
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 58px;
    color: #212326;
    margin: 8px 0 24px 0
}

.seemore-subhead {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #637381;
    width: 60%
}

.seemore-categories {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    padding-top: 60px;
    color: #212326;

}

.seemore-button-div {
    display: flex;
    width: 60%;
    justify-content: flex-start;
    display: grid;
    grid-template-columns: repeat(3, 33%);

}

.button-clicked {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    align-self: center;
    padding: 12px 8px;
    background: #009AE0;
    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.06);
    border-radius: 32px;
    border: 1px solid #009AE0;
    cursor: pointer;
    outline: none
}

.button-notclicked {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #637381;
    align-self: center;
    padding: 12px 8px;
    background: #F6F9FC;
    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.06);
    border-radius: 32px;
    border: 1px solid #637381;
    cursor: pointer;
    outline: none;
}

.seemore-second {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    margin: auto;


}

.seemore-second-details {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    width: 85%;
    margin: 0 24px;
}

.button-margin {
    margin-left: 24px;
}

.student-margin {
    margin-left: 24px;
}

@media(max-width:1000px) {
    .seemore {
        margin-top: 7rem;
    }

    .seemore-first {
        width: 100%;
        background: #F6F9FC;
        padding: 24px;

    }

    .seemore-second-details {
        width: 90%;
    }

    .seemore-head {
        font-size: 32px;
        line-height: 42px;
    }

    .seemore-subhead {
        font-size: 16px;
        line-height: 24px;
        width: 100%
    }

    .seemore-button-div {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, 50%);
        grid-row-gap: 14px;
    }

    .student-margin {
        margin-left: 0px;
    }

    .button-margin {
        margin-left: 12px;
    }

    .seemore-categories {
        font-size: 24px;
        line-height: 29px;
    }

    .button-notclicked {
        padding: 8px 4px;
        font-weight: 500;
        font-size: 8px;
        line-height: 20px;

    }

    .button-clicked {
        padding: 8px 4px;
        font-weight: 500;
        font-size: 8px;
        line-height: 20px;

    }

    .seemore-second {
        width: 90%;
    }

}

@media(max-width:350px) {
    .button-clicked {
        font-size: 7px;
        padding: 8px 0;
    }

    .button-notclicked {
        font-size: 7px;
        padding: 8px 0;

    }
}

@media(min-width:2000px) {
    .seemore-button-div {
        width: 30%;
    }
}
.referral {
  // margin-top: 80px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card {
  background: linear-gradient(180deg, #f6f9fe 0%, rgba(255, 255, 255, 0) 100%);
  // margin: 0 16px;
  width: 90%;
  position: absolute;
  top: 80%;
  z-index: 1;
  margin-bottom: 74px;
  background: #e5e5e5;
}

.firstCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background: #ffffff;
  margin-bottom: -1px;
  // border-bottom: 1px solid #e5e5e5;
  // background: linear-gradient(180deg, #f6f9fe 0%, rgba(255, 255, 255, 0) 100%);
}

.firstcard-blueText {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height, or 150% */
  margin: 0;
  color: #004cbe;
}

.firstcard-blackText {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;
  /* or 150% */
  margin: 0;
  color: #121212;
}

.firstcard-button-div {
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
}

.firstcard-referBtn {
  background: #009AE0;
  border-radius: 4px;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
  padding: 16px 0;
  width: 100%;
}

.firstCard-validity {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  padding-top: 8px;
}

.secondCard {
  display: flex;
  padding: 16px;
  flex-direction: column;
  background: #ffffff;
}

.secondCard-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height, or 150% */


  color: #121212;

}

.secondCard-div {
  display: flex;
}

.secondCard-div-heading {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */

  color: rgba(0, 0, 0, 0.87);
}

.thirdCard {
  display: flex;
  flex-direction: column;
  // padding: 16px;
  background: #ffffff;
  margin: 24px 0;
  // margin-bottom: 100px;
}

.thirdCard-heading {
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 4px;
}

.thirdCard-heading-head {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  margin: 0;
  color: rgba(0, 0, 0, 0.87);
}

.thirdCard-heading-tc {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  outline: none;
  border: none;
  background: #fff;

  color: #009ae0;
}

.thirdCard-content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  padding-left: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.thirdCard-footer {
  border-top: 1px solid #e5e5e5;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  padding: 16px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 4px;
}

.fourthCard {
  margin: 24px 0;
  // margin-bottom: 100px;
}

.fifthCard {
  // height: 638px;
  margin-bottom: 100px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
}

.fifthCard-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0px;
  border-bottom: 1px solid #E5E5E5;

}

.fifthCard-footer {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  /* or 127% */
  padding: 16px;
  margin: 0px;
  border-top: 1px solid #E5E5E5;

  color: rgba(0, 0, 0, 0.6);
}

.share-button-div {
  justify-content: center;
  z-index: 1;
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.24);
  padding: 16px;
  font-family: SF Pro Text;
}

.share-button {
  background: #25d366;
  border-radius: 3px;
  padding: 11px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  width: 90%;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-drop {
    width: 100%;
    padding: 0px 10px;
    height: 32px;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 4px;
}

.product-drop:focus {
    outline: none;
}

.product-mobile-view {
    width: 100%;
    padding-top: 24px;
}

.product-mobile-heading {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;
    color: #212B36;
}
body {
    font-family: SF Pro Text;
}
/* .Container {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.Container ul {
  list-style-type: none;
  display: flex;
}

.Container img {
  width: 100%;
  height: 100%;
} */

.Carousel_Container__EM1ZN {
  position: relative;
  /* overflow: hidden; */
  /* height: 6rem; */
  /* margin: 20px auto 0 auto; */
  /* border-radius: 4px; */
}

.Carousel_Container__EM1ZN ul {
  position: relative;
  display: flex;
  margin: 0;
  padding: 0;
  /* height: 200px; */
  list-style: none;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  margin-top: 16px;
}

.Carousel_Container__EM1ZN ul li {
  position: relative;
  display: flex;
  float: left;
  flex-shrink: 0;
  padding: 0;
  /* width: 300px;
  height: 200px; */
  /* background: #333944; */
  background: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  /* height: 10px; */

  /* line-height: 200px; */
}

/* .Container ul li p {
  margin: 0px;
  height: 100%;
} */

.Carousel_Container__EM1ZN button {
  position: absolute;
  top: 30px;
  cursor: default;
  height: 100%;
  width: 55px;
  background: none;
  color: white;
  border: none;
  outline: none;
  display: block;
  -webkit-tap-highlight-color: transparent;
}

.Carousel_roundButton__1tsVi {
  height: 4rem;
  align-items: center;
  margin-top: 16px;
  display: flex;
  width: 4rem;
  justify-content: center;
  cursor: pointer;
  background: white;
  opacity: 0.9;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(172, 173, 175, 0.2),
    0px 3px 4px rgba(172, 173, 175, 0.12), 0px 2px 4px rgba(172, 173, 175, 0.14);
}

.Carousel_disabledRound__11bHK {
  box-shadow: none !important;
  opacity: 0.9;
  /* cursor: not-allowed; */
}

.Carousel_disabledRound__11bHK:hover {
  opacity: 0.9 !important;
}

.Carousel_roundButton__1tsVi:hover {
  -webkit-box-shadow: 7px 13px 13px -9px rgba(0, 0, 0, 0.33);
  opacity: 1;
  box-shadow: 7px 13px 13px -9px rgba(0, 0, 0, 0.33);
}

.Carousel_roundButton__1tsVi:active {
  box-shadow: 0px 1px 5px rgba(172, 173, 175, 0.2),
    0px 3px 4px rgba(172, 173, 175, 0.12), 0px 2px 4px rgba(172, 173, 175, 0.14);
  -webkit-box-shadow: 0px 1px 5px rgba(172, 173, 175, 0.2),
    0px 3px 4px rgba(172, 173, 175, 0.12), 0px 2px 4px rgba(172, 173, 175, 0.14);
  -moz-box-shadow: 0px 1px 5px rgba(172, 173, 175, 0.2),
    0px 3px 4px rgba(172, 173, 175, 0.12), 0px 2px 4px rgba(172, 173, 175, 0.14);
  opacity: 1;
}

.Carousel_Container__EM1ZN button.Carousel_Left__2gvDp {
  /* background: linear-gradient(to right, #AAAAAA77, #AAAAAA00);
  background: linear-gradient(to left, transparent 0%, rgba(0, 0, 0, 0.25) 100%); */
}

.Carousel_Left__2gvDp {
  cursor: pointer;
}

.Carousel_Right__29t8B {
  cursor: pointer;
}

.Carousel_Container__EM1ZN button.Carousel_Right__29t8B {
  /* background: linear-gradient(to right, #AAAAAA00, #AAAAAA77);
  background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.25) 100%);
  background: grey; */
}

.Carousel_Container__EM1ZN button.Carousel_Left__2gvDp {
  left: 2rem;
  width: auto;
}

.Carousel_Container__EM1ZN button.Carousel_Right__29t8B {
  right: 2rem;
  width: auto;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */

/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'angle-left';
    src: url('./font/angle-left.svg?23394832#angle-left') format('svg');
  }
}
*/

.Carousel_IconRight__4Vmx_:before,
.Carousel_IconLeft__G_0Ot:before {
  font-family: "icon-fonts";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  /* width: 1em; */
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.Carousel_IconRight__4Vmx_:before {
  content: "\F105";
}

/* '' */

.Carousel_IconLeft__G_0Ot:before {
  content: "\F104";
}

/* '' */

/*@media(max-width: 500px) {
  .Container button {
    display: block;
    -webkit-tap-highlight-color: transparent;
  }
}*/
.Carousel_dotNavigations__2aEHj {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
}

.Carousel_dots__wcUdE {
  cursor: pointer;
  background: rgba(0, 154, 224, 0.4);
  display: flex;
  height: 10px;
  margin: 0 4px;
  width: 10px;
  border-radius: 50%;
}

.Carousel_activeDot__1-dSm {
  background: #009ae0;
}

.Carousel_carouselHeader__WDA4a {
  padding-left: 1rem;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  /* text-transform: uppercase; */
  color: rgba(0, 0, 0, 0.87);
}

.Carousel_carouselUl__3dIm1>li {
  margin: 0 1rem;
}

.Carousel_carouselUl__3dIm1>li:first-child {
  padding-left: 0;
  margin-left: 0;
}

.Carousel_carouselUl__3dIm1>li:last-child {
  padding-left: 0;
  margin-right: 0;
}

@media(max-width:1000px) {
  .Carousel_Container__EM1ZN button {
    top: 70px;

  }

  .Carousel_Container__EM1ZN button.Carousel_Left__2gvDp {
    left: 1rem;
    width: auto;
  }

  .Carousel_Container__EM1ZN button.Carousel_Right__29t8B {
    right: 1rem;
    width: auto;
  }
}
.TabContainer {

    display: flex;
    flex-direction: column;
    width: 100%;
}

.TabsHeader {
    width: 100%;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* border-top: 1px solid #E5E5E5; */
    border-bottom: 1px solid #E5E5E5;
}

.SubTabsHeader {
    width: 100%;
    display: flex;
    font-size: 14px;
    font-weight: 500;
     /* border-top: 1px solid #E5E5E5; */


}

.Tab {
    display: flex;
    align-items: center;
    justify-content: center;
/* identical to box height */
    font-weight: 500;  
    letter-spacing: 1px;
    /* text-transform: uppercase; */
    padding:16px 8px;
    color: rgba(0, 0, 0, 0.6);
    width: 25%;
    cursor: pointer;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #637381;
    /* min-width: 100px;
    max-width: auto; */
}

.Tab.Selected {
    color: rgba(0, 0, 0, 0.87);
    border-bottom:  2px solid #009AE0;
    /* min-width: 100px; */
    /* max-width: auto; */
}

.emptyStateDivision {
    height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10%;
}

.emptyState_text {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    padding: 24px 0;
}

.emptyState_subtext {
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;

    color: rgba(0, 0, 0, 0.6);
}

.emptyState_button {
    border: 1px solid #009AE0;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.emptyState_button--text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    padding: 8px 32px;
    color: #009AE0;
}

.borderBottom {
    border-bottom: 1px solid #E5E5E5;
}

@media(max-width:340px){
    .Tab{
        font-size:10px;
    }

}


/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
-medium-screen
-desktop
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@-webkit-keyframes easyLeft {
  0% {
    left: -10rem;
    opacity: 0; }
  100% {
    left: 0;
    top: 0;
    opacity: 1; } }

@keyframes easyLeft {
  0% {
    left: -10rem;
    opacity: 0; }
  100% {
    left: 0;
    top: 0;
    opacity: 1; } }

@-webkit-keyframes easyRight {
  0% {
    right: -10rem;
    opacity: 0; }
  100% {
    right: 0;
    top: 0;
    opacity: 1; } }

@keyframes easyRight {
  0% {
    right: -10rem;
    opacity: 0; }
  100% {
    right: 0;
    top: 0;
    opacity: 1; } }

.fade-in {
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  scroll-behavior: smooth;
  font-size: 62.5%; }
  @media only screen and (max-width: 80em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 50%; } }
  @media only screen and (min-width: 112.5em) {
    html {
      font-size: 75%; } }

body {
  box-sizing: border-box;
  text-shadow: none;
  box-shadow: none;
  outline: none;
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  text-shadow: none;
  background: #E5E5E5; }
  @media only screen and (max-width: 56.25em) {
    body {
      padding: 0; } }

::selection {
  background-color: #009ae0;
  color: #ffffff; }

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  /*font-size: 16px;*/ }

.heading--mega {
  font-size: 4.8rem;
  font-weight: 700;
  line-height: 7.2rem; }
  @media only screen and (max-width: 64em) {
    .heading--mega {
      font-size: 4rem;
      font-weight: 700;
      line-height: 4rem; } }
  @media only screen and (max-width: 37.5em) {
    .heading--mega {
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 3.2rem; } }

.heading--medium {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 4.8rem; }
  @media only screen and (max-width: 48em) {
    .heading--medium {
      text-align: center;
      font-size: 2.4rem; } }

.p--medium {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.7rem; }

.p--small {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem; }

.green--text {
  color: #008489; }
  .green--text--2 {
    color: #009ae0; }

.grid--1 {
  display: grid;
  grid-template-columns: [first-start] 10rem [first-end second-start] minmax(50rem, 1.5fr) [second-end third-start] minmax(55rem, 1fr) [third-end fourth-start] 15rem [fourth-end]; }
  @media only screen and (max-width: 80em) {
    .grid--1 {
      grid-template-columns: [first-start] 10rem [first-end second-start] minmax(60rem, 1.5fr) [second-end third-start] minmax(50rem, 1fr) [third-end fourth-start] 10rem [fourth-end]; } }
  @media only screen and (max-width: 64em) {
    .grid--1 {
      grid-template-columns: [first-start] 9rem [first-end second-start] minmax(50rem, 1.5fr) [second-end third-start] minmax(50rem, 1fr) [third-end fourth-start] 9rem [fourth-end]; } }
  @media only screen and (max-width: 64em) {
    .grid--1 {
      grid-template-columns: [first-start] 10rem [first-end second-start] minmax(50rem, 1fr) [second-end third-start] minmax(40rem, 1fr) [third-end fourth-start] 5rem [fourth-end]; } }
  @media only screen and (max-width: 56.25em) {
    .grid--1 {
      grid-template-columns: [first-start] 10rem [first-end second-start] minmax(40rem, 1fr) [second-end third-start] minmax(40rem, 1fr) [third-end fourth-start] 5rem [fourth-end]; } }
  @media only screen and (max-width: 48em) {
    .grid--1 {
      grid-template-columns: [first-start] 5rem [first-end second-start] minmax(40rem, 1fr) [second-end third-start] minmax(40rem, 1fr) [third-end fourth-start] 5rem [fourth-end]; } }
  @media only screen and (max-width: 37.5em) {
    .grid--1 {
      display: flex !important;
      flex-direction: column !important; } }

.grid--2 {
  display: grid !important;
  grid-template-columns: [first-start] 1fr [first-end second-start] 1fr !important;
  grid-auto-flow: column !important;
  grid-column-gap: 5rem !important; }
  @media only screen and (max-width: 48em) {
    .grid--2 {
      display: flex !important;
      flex-direction: column !important;
      align-items: center; } }

.grid--3 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 4rem; }
  @media only screen and (max-width: 37.5em) {
    .grid--3 {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 3rem;
      grid-column-gap: 1rem; } }

.grid--4 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 4rem; }
  @media only screen and (max-width: 37.5em) {
    .grid--4 {
      display: flex !important;
      flex-direction: column !important; } }

.grid--5 {
  display: grid;
  grid-template-columns: repeat(3, 1fr); }
  @media only screen and (max-width: 64em) {
    .grid--5 {
      grid-column-gap: 20rem; } }
  @media only screen and (max-width: 37.5em) {
    .grid--5 {
      grid-column-gap: 1rem; } }

.grid--6 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 23rem; }

.grid--7 {
  display: grid;
  grid-template-columns: repeat(5, 8rem);
  grid-gap: 1rem;
  grid-template-rows: 4rem;
  align-items: center; }

.grid--8 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr; }

.grid--9 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem; }
  @media only screen and (max-width: 37.5em) {
    .grid--9 {
      display: flex !important;
      flex-direction: column !important; } }

.link {
  padding: 1rem 3rem;
  text-decoration: none;
  font-size: 2rem;
  border-radius: 15px; }

.SVGfigure {
  height: 60rem;
  width: 60rem; }

.forMargin {
  margin-left: 4rem; }

.circle {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  align-self: center; }

.spinnerDiv {
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center; }

.fw500 {
  font-weight: 500; }

.blueStrip {
  width: 100%;
  background-color: #0E3A62;
  height: 2.4rem;
  display: none; }
  @media only screen and (max-width: 37.5em) {
    .blueStrip {
      display: block;
      position: -webkit-sticky;
      position: sticky;
      top: 0px; } }

.cursor-free {
  cursor: pointer !important; }

.marginTop13 {
  margin-top: 13rem; }
  @media only screen and (max-width: 37.5em) {
    .marginTop13 {
      margin-top: 0rem; } }

.marginTop11 {
  margin-top: 11rem; }
  @media only screen and (max-width: 37.5em) {
    .marginTop11 {
      margin-top: 0rem; } }

.marginTop7 {
  margin-top: 7rem; }

.marginTop5 {
  margin-top: 5rem; }

.marginTop3 {
  margin-top: 3rem; }

.marginTop1 {
  margin-top: 1rem; }

.marginLeft3 {
  margin-left: 3.8rem; }
  @media only screen and (max-width: 37.5em) {
    .marginLeft3 {
      margin-left: 0;
      margin-bottom: 2.2rem; } }

.colRev {
  flex-direction: column-reverse !important; }

a {
  text-decoration: none;
  color: white; }

.flexrow {
  display: flex; }

@media only screen and (max-width: 37.5em) {
  .tutorsMobile {
    margin-left: -2rem; } }

@media only screen and (max-width: 37.5em) {
  .jcCheat {
    justify-content: center; } }

@media only screen and (max-width: 37.5em) {
  .p6rem {
    padding: 0 6rem !important; } }

@media only screen and (max-width: 37.5em) {
  .p2rem {
    padding: 0 2rem !important; } }

.btn {
  box-shadow: none;
  text-shadow: none;
  border: none;
  transition: all 0.3s ease-in; }
  .btn--bookADemo {
    padding: 16px;
    font-size: 1.6rem;
    border-radius: 4px;
    background-color: #009ae0;
    color: #ffffff;
    outline: none;
    border: none;
    cursor: pointer; }
    @media only screen and (max-width: 37.5em) {
      .btn--bookADemo {
        padding: 1.4rem 1.6rem; } }

.btn:hover {
  -webkit-transform: translateY(-0.2rem);
  transform: translateY(-0.2rem);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); }

button {
  outline: none;
  border: none; }

.landing {
  background-position: center;
  margin-top: 7rem;
  padding: 6.5rem 0 0 0;
  background: linear-gradient(180.28deg, #E8F4FF -10.81%, #FFFFFF 86.3%);
  height: 80vh; }
  @media only screen and (max-width: 37.5em) {
    .landing {
      padding: 0;
      margin-top: 6rem; } }
  .landing__content {
    grid-column: second-start/second-end;
    display: flex !important;
    flex-direction: column !important;
    align-self: center;
    position: relative;
    color: #fff;
    height: 100%; }
    @media only screen and (max-width: 37.5em) {
      .landing__content {
        text-align: center; } }
    .landing__content--main {
      font-size: 4.8rem;
      font-weight: 700;
      line-height: 6rem; }
      @media only screen and (max-width: 37.5em) {
        .landing__content--main {
          margin-top: 3rem;
          font-size: 3.2rem;
          padding: 2.7rem;
          line-height: 4rem;
          display: block; } }
      .landing__content--main--corona {
        font-size: 6rem;
        display: flex; }
        @media only screen and (max-width: 37.5em) {
          .landing__content--main--corona {
            font-size: 7rem;
            margin-top: 8rem;
            padding: 0;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            text-align: center;
            flex-direction: column; } }
      @media only screen and (max-width: 37.5em) {
        .landing__content--main--corona > div {
          margin-bottom: 2rem; } }
    .landing__content--sub {
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 3rem; }
      @media only screen and (max-width: 37.5em) {
        .landing__content--sub {
          font-size: 2rem;
          margin-top: 0rem;
          padding: 0 2.2rem 2.7rem;
          display: block; } }
      .landing__content--sub--corona {
        font-size: 3.2rem !important;
        line-height: 4rem !important; }
        @media only screen and (max-width: 37.5em) {
          .landing__content--sub--corona {
            margin-top: 4rem;
            padding: 0 2rem; } }
      .landing__content--sub--hashtag {
        margin-top: 1rem !important;
        font-weight: 300; }
        @media only screen and (max-width: 37.5em) {
          .landing__content--sub--hashtag {
            font-size: 2.5rem; } }
      .landing__content--sub--prompt {
        margin-top: 5rem !important;
        font-weight: 300;
        font-size: 4rem; }
        .landing__content--sub--prompt--2 {
          margin-bottom: 3rem; }
        @media only screen and (max-width: 37.5em) {
          .landing__content--sub--prompt {
            margin-top: 2rem !important;
            padding: 0;
            font-size: 3.2rem;
            font-weight: 700; } }
  .landing__form {
    background-color: #ffffff;
    padding: 32px 0px 28px 0px;
    width: 50rem;
    border-radius: 4px;
    margin-left: 12rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5); }
    @media only screen and (max-width: 80em) {
      .landing__form {
        margin-left: 2rem; } }
    @media only screen and (max-width: 64em) {
      .landing__form {
        margin-left: 7rem; } }
    @media only screen and (max-width: 56.25em) {
      .landing__form {
        padding: 2.7rem 3.2rem 2.7rem 2.2rem; } }
    @media only screen and (max-width: 48em) {
      .landing__form {
        padding: 2.7rem 4.2rem 2.7rem 1.2rem; } }
    @media only screen and (max-width: 37.5em) {
      .landing__form {
        margin: 0;
        text-align: left;
        max-width: unset;
        padding: 2.7rem 0; } }
    .landing__form--header--heading {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 29px;
      padding: 24px 32px 0px 32px;
      border-top: 1px solid #E5E5E5; }
      @media only screen and (max-width: 80em) {
        .landing__form--header--heading {
          font-size: 24px; } }
      @media only screen and (max-width: 48em) {
        .landing__form--header--heading {
          line-height: 24px; } }
      @media only screen and (max-width: 37.5em) {
        .landing__form--header--heading {
          font-size: 18px; } }
    .landing__form--header--content {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 500;
      padding-top: 10px; }
      @media only screen and (max-width: 48em) {
        .landing__form--header--content {
          margin-top: 2.7rem; } }
      @media only screen and (max-width: 37.5em) {
        .landing__form--header--content {
          margin-top: 1rem; } }

.web-down-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px; }

.mobile-down-arrow {
  display: none;
  align-items: center;
  justify-content: center; }

.topDivSubheading {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  padding-top: 24px;
  margin: 0 0 4px 0;
  color: #5E6D89; }

.landing-top-flex {
  background: #ffffff;
  display: flex;
  flex-direction: column; }

.landing-embrace {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 8px;
  width: 50%;
  margin: auto; }

.landing-embrace-heading {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #212326; }

.landing-embrace-subheading {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #6B7177; }

.form-basic {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15), 0px 0px 0px rgba(63, 63, 68, 0.05); }

.topDivHeading {
  font-size: 40px;
  line-height: 52px;
  color: #000000; }

.landing-topImage {
  width: 379px;
  margin-top: 60px; }

@media (max-width: 1000px) {
  .landing {
    height: 100%; }
  .web-down-arrow {
    display: none; }
  .mobile-down-arrow {
    display: none; }
  .topDiv-padding {
    padding: 3rem 2rem 40px 2rem;
    text-align: left; }
  .topDivHeading {
    padding: 0 0 2rem 0;
    font-size: 32px;
    display: block;
    flex-direction: column;
    line-height: 40px; }
  .topDivSubheading {
    font-size: 16px;
    line-height: 24px;
    padding-top: 0px;
    margin-bottom: 0; }
  .indiaFlag {
    bottom: 13px; }
  .landing-embrace {
    padding-top: 60px;
    width: 90%; }
  .landing-topImage {
    width: 100%;
    margin-top: 32px; }
  .landing__content {
    margin-left: 0px; } }

.landing__Image {
  width: 55rem;
  height: 45rem;
  align-self: center; }
  @media only screen and (max-width: 37.5em) {
    .landing__Image {
      display: none; } }

.instantFix {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 2.5rem; }
  @media only screen and (max-width: 37.5em) {
    .instantFix {
      align-self: center;
      font-size: 2rem; } }

@media only screen and (min-width: 2000px) and (max-width: 2700px) {
  .landing {
    padding: 0; }
  .landing__form {
    width: 46rem;
    margin-left: 16px; } }

@media only screen and (min-width: 1925px) {
  .landing__form {
    margin-top: 6rem; } }

@media only screen and (max-width: 1925px) {
  .landing__form {
    margin-top: 6rem; } }

@media only screen and (max-width: 1875px) {
  .landing__form {
    margin-top: 4rem; } }

@media only screen and (max-width: 1825px) {
  .landing__form {
    margin-top: 2rem; } }

@media only screen and (width: 1800px) {
  .landing__form {
    margin-top: 1rem; } }

@media only screen and (max-width: 1799px) {
  .landing__form {
    margin-top: 13rem; } }

@media only screen and (max-width: 1775px) {
  .landing__form {
    margin-top: 12rem; } }

@media only screen and (max-width: 1750px) {
  .landing__form {
    margin-top: 11rem; } }

@media only screen and (max-width: 1725px) {
  .landing__form {
    margin-top: 10rem; } }

@media only screen and (max-width: 1700px) {
  .landing__form {
    margin-top: 9rem; } }

@media only screen and (max-width: 1675px) {
  .landing__form {
    margin-top: 8rem; } }

@media only screen and (max-width: 1650px) {
  .landing__form {
    margin-top: 7rem; } }

@media only screen and (max-width: 1625px) {
  .landing__form {
    margin-top: 6rem; } }

@media only screen and (max-width: 1600px) {
  .landing__form {
    margin-top: 5rem; } }

@media only screen and (max-width: 1575px) {
  .landing__form {
    margin-top: 4rem; } }

@media only screen and (max-width: 1550px) {
  .landing__form {
    margin-top: 3rem; } }

@media only screen and (max-width: 1525px) {
  .landing__form {
    margin-top: 2rem; } }

@media only screen and (max-width: 1500px) {
  .landing__form {
    margin-top: 1rem; } }

@media (max-width: 1200px) {
  .landing__form {
    width: auto; } }

@media (max-width: 355px) {
  .landing__form--header--heading {
    font-size: 15px; } }

.line-1 {
  white-space: nowrap;
  overflow: hidden; }

/* Animation */
.anim-typewriter {
  -webkit-animation: typewriter 4s steps(44) 1s 1 normal both, blinkTextCursor 500ms steps(44) infinite normal;
          animation: typewriter 4s steps(44) 1s 1 normal both, blinkTextCursor 500ms steps(44) infinite normal; }

@-webkit-keyframes typewriter {
  from {
    width: 0; }
  to {
    width: 24em; } }

@keyframes typewriter {
  from {
    width: 0; }
  to {
    width: 24em; } }

@-webkit-keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75); }
  to {
    border-right-color: transparent; } }

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75); }
  to {
    border-right-color: transparent; } }

@media only screen and (max-width: 37.5em) {
  .landing__content--sub--prompt--2 {
    font-size: 2.5rem; } }

.form {
  margin: 1rem 0;
  display: flex !important;
  flex-direction: column !important; }
  @media only screen and (max-width: 37.5em) {
    .form {
      min-width: 100%; } }
  .form__input {
    color: #212B36;
    margin: 1rem 0;
    border: 1px solid #979797;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 1.6rem;
    padding: 1.4rem 1.6rem;
    color: #999999; }
    .form__input--2 {
      width: 48%;
      left: 0; }
  .form__button {
    margin-top: 2.4rem; }

.select-css {
  display: block;
  padding: 1.4rem 1.6rem;
  border-radius: 4px;
  font-size: 1.6rem;
  color: #999999;
  border: 1px solid #979797;
  width: 50%;
  max-width: 50%;
  margin: 1rem 0 1rem 0.8rem;
  -webkit-appearance: none;
          appearance: none;
  background-color: #fff; }

.select-css::-ms-expand {
  display: none; }

.select-css:focus {
  outline: none; }

.select-css option {
  font-weight: normal; }

.successPopup {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center; }

.successPopup__content {
  background-color: #009ae0;
  padding: 3rem 6rem;
  border-radius: 5px;
  width: 30%;
  text-align: center; }
  @media only screen and (max-width: 37.5em) {
    .successPopup__content {
      width: 80%; } }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1000; }
  90% {
    opacity: 0.1; }
  100% {
    opacity: 0;
    display: none;
    z-index: 0;
    visibility: hidden; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 1000; }
  90% {
    opacity: 0.1; }
  100% {
    opacity: 0;
    display: none;
    z-index: 0;
    visibility: hidden; } }

input {
  outline: none;
  color: #212B36; }

.successOut {
  -webkit-animation: fadeOut 4s;
  animation: fadeOut 4s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.whatsappBtn {
  background: #25D366;
  border-radius: 3px;
  padding: 11px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  width: 100%;
  cursor: pointer;
  color: #FFFFFF; }

.whatsappAnchor {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none; }

.whatsappAnchor:hover {
  color: #FFFFFF; }

.spacing {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.yourNumber-input {
  width: 100%;
  padding-left: 60px;
  color: #212B36; }

.indiaFlag {
  position: absolute;
  left: 10px;
  bottom: 18px; }

.form-Bottom {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 106px 0 0 0; }

.phone-line {
  position: absolute;
  left: 50px;
  top: 20px; }

@media (max-width: 1000px) {
  .phone-line {
    top: 12px; }
  .indiaFlag {
    position: absolute;
    left: 10px;
    bottom: 13px; } }

.tnc {
  padding-top: 16rem;
  text-align: center;
  background: #ffffff; }
  @media only screen and (max-width: 37.5em) {
    .tnc {
      padding-top: 10rem; } }

.tnc__heading {
  font-weight: 700;
  font-size: 4rem;
  line-height: 3rem; }
  .tnc__heading--2 {
    margin-top: 2rem;
    color: #777777;
    line-height: 30px;
    font-size: 24px; }

.tnc__heading::after {
  content: "";
  border-top: 1px solid #333333;
  margin-top: 1rem; }

.tnc__list {
  text-align: left;
  padding: 0 10rem 4rem 10rem; }

.tnc__item {
  margin: 3rem 0; }

.tnc__item--title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: #444444; }

.tnc__item--content {
  margin: 1rem 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #555555; }

.policy {
  text-align: center;
  font-size: 1.6rem; }
  .policy__content {
    margin: 3rem 0 0 0;
    padding: 1rem 11rem 7rem 11rem; }
    @media only screen and (max-width: 37.5em) {
      .policy__content {
        padding: 1rem 4rem 7rem 4rem; } }
  .policy__list {
    text-align: left;
    padding: 1rem 14rem; }
    @media only screen and (max-width: 37.5em) {
      .policy__list {
        padding: 1rem 4rem; } }
    .policy__list--item {
      list-style: circle; }

@media only screen and (max-width: 900px) {
  .tnc__list {
    text-align: left;
    padding: 0 5rem 3rem 5rem; } }

@media only screen and (max-width: 768px) {
  .tnc__list {
    text-align: left;
    padding: 0 4rem 3rem 4rem; } }

@media only screen and (max-width: 600px) {
  .tnc__heading {
    font-weight: 700;
    font-size: 3rem;
    line-height: 2.25rem; }
  .tnc__list {
    text-align: left;
    padding: 0 3rem 3rem; }
  .tnc__item {
    margin: 2rem 0; }
  .tnc__item--title {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #444444; }
  .tnc__item--content {
    margin: 1rem 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #555555; } }

@media only screen and (max-width: 480px) {
  .tnc__list {
    text-align: left;
    padding: 0 2rem 3rem 2rem; } }

@media only screen and (max-width: 320px) {
  .tnc__list {
    text-align: left;
    padding: 0 1rem 3rem 1rem; } }

.pointer-topDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(0, 154, 224, 0.04);
  padding: 60px 0;
  background: linear-gradient(180.28deg, #E8F4FF -10.81%, #FFFFFF 86.3%); }

.flex-class-column {
  display: flex;
  flex-direction: column;
  width: 30%; }

.flex-class-row {
  display: flex;
  margin: 12px 0; }

.pointer-firstbox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%; }

.pointer-heading {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 48px;
  color: #212326;
  margin-bottom: 16px; }

.pointer-content {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  color: #6B7177; }

.pointer-point {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  color: #6B7177; }

.pointer-secondbox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-top: 66px; }

@media (max-width: 1000px) {
  .pointer-firstbox {
    flex-direction: column; }
  .pointer-secondbox {
    flex-direction: column-reverse; }
  .flex-class-column {
    width: 100%;
    text-align: center;
    padding: 0 16px; }
  .pointer-heading {
    font-size: 32px;
    line-height: 48px; }
  .pointer-content {
    font-size: 16px;
    line-height: 19px; }
  .pointer-list-div {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 87%;
    justify-content: center;
    margin: auto; } }

@media (max-width: 340px) {
  .pointer-list-div {
    width: 100%; } }

.testi-box {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(63, 63, 68, 0.15), 0px 0px 0px rgba(63, 63, 68, 0.05);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 50%; }

.testi-head {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #515978;
  text-align: left;
  margin: 0; }

.testi-colen {
  width: 24px; }

.testi-content {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #6B7177;
  margin: 16px 0 32px 0;
  text-align: left; }

.testi-image-div {
  display: flex; }

.testi-name {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #515978;
  margin-bottom: 4px;
  text-align: left;
  width: 100%; }

.testi-institute {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #6B7177;
  text-align: left;
  width: 100%; }

.testi-image {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 24px;
  flex-shrink: 0; }

.major-div {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
  justify-content: center;
  margin: auto; }

.major-heading {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #212326; }

.major-subheading {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 24px;
  color: #6B7177; }

@media (max-width: 1000px) {
  .testi-box {
    width: 85%;
    padding: 16px; }
  .testi-head {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px; }
  .testi-content {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px; }
  .testi-name {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px; }
  .testi-institute {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px; } }

.frequent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.frequent-heading {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #212326;
  margin: 8px 0;
  padding: 0 8px; }

.frequent-subheading {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #6B7177;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px; }

.frequent-grid {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(63, 63, 68, 0.15), 0px 0px 0px rgba(63, 63, 68, 0.05);
  border-radius: 3px;
  width: 80%;
  margin: 60px auto 32px auto; }

.frequent-grid-item {
  display: flex;
  flex-direction: column;
  padding: 24px; }

.frequent-grid-item-head {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #212326; }

.frequent-grid-item-content {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #6B7177; }

.see-more-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0 100px 0; }

.see-more {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid #009AE0;
  border-radius: 4px;
  color: #009AE0;
  display: flex;
  justify-content: center;
  padding: 12px 24px;
  background: #FFFFFF;
  cursor: pointer; }

@media (max-width: 1000px) {
  .frequent-grid {
    grid-template-columns: repeat(1, 100%);
    width: 90%;
    margin: 0 auto; } }

.seemore {
  margin-top: 5rem;
  display: flex;
  align-items: center;
  background: #ffffff;
  flex-direction: column; }

.seemore-first {
  width: 100%;
  background: #F6F9FC;
  padding: 60px 7% 40px 7%; }

.seemore-head {
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 58px;
  color: #212326;
  margin: 8px 0 24px 0; }

.seemore-subhead {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  color: #637381;
  width: 60%; }

.seemore-categories {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  padding-top: 60px;
  color: #212326; }

.seemore-button-div {
  display: flex;
  width: 60%;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: repeat(3, 33%); }

.button-clicked {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
  align-self: center;
  padding: 12px 8px;
  background: #009AE0;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.06);
  border-radius: 32px;
  border: 1px solid #009AE0;
  cursor: pointer;
  outline: none; }

.button-notclicked {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #637381;
  align-self: center;
  padding: 12px 8px;
  background: #F6F9FC;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.06);
  border-radius: 32px;
  border: 1px solid #637381;
  cursor: pointer;
  outline: none; }

.seemore-second {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
  margin: auto; }

.seemore-second-details {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 85%;
  margin: 0 24px; }

.button-margin {
  margin-left: 24px; }

.student-margin {
  margin-left: 24px; }

@media (max-width: 1000px) {
  .seemore {
    margin-top: 7rem; }
  .seemore-first {
    width: 100%;
    background: #F6F9FC;
    padding: 24px; }
  .seemore-second-details {
    width: 90%; }
  .seemore-head {
    font-size: 32px;
    line-height: 42px; }
  .seemore-subhead {
    font-size: 16px;
    line-height: 24px;
    width: 100%; }
  .seemore-button-div {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 50%);
    grid-row-gap: 14px; }
  .student-margin {
    margin-left: 0px; }
  .button-margin {
    margin-left: 12px; }
  .seemore-categories {
    font-size: 24px;
    line-height: 29px; }
  .button-notclicked {
    padding: 8px 4px;
    font-weight: 500;
    font-size: 8px;
    line-height: 20px; }
  .button-clicked {
    padding: 8px 4px;
    font-weight: 500;
    font-size: 8px;
    line-height: 20px; }
  .seemore-second {
    width: 90%; } }

@media (max-width: 350px) {
  .button-clicked {
    font-size: 7px;
    padding: 8px 0; }
  .button-notclicked {
    font-size: 7px;
    padding: 8px 0; } }

@media (min-width: 2000px) {
  .seemore-button-div {
    width: 30%; } }

.question-box {
  display: flex;
  flex-direction: column;
  width: 100%; }

.question-firstdiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  outline: none; }

.question-firstline {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #212326;
  margin: 0;
  transition: all 0.4s ease-out; }

.question-secondline {
  white-space: pre-line;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  transition: all 0.4s ease-in;
  color: rgba(0, 0, 0, 0.6); }

@media (max-width: 1000px) {
  .question {
    margin: 30px auto; }
  .question-firstline {
    font-size: 16px; }
  .question-secondline {
    font-size: 16px;
    line-height: 32px; } }

.product-drop {
  width: 100%;
  padding: 0px 10px;
  height: 32px;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 4px; }

.product-drop:focus {
  outline: none; }

.product-mobile-view {
  width: 100%;
  padding-top: 24px; }

.product-mobile-heading {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 8px;
  color: #212B36; }

.image-box {
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 50%; }

@media (max-width: 1000px) {
  .image-box {
    width: 100%;
    padding: 0 16px; } }

.container {
  display: flex;
  flex-direction: column;
  padding: 80px 0 0 0;
  background: #ffffff; }

.firstBox {
  display: flex;
  flex-direction: column; }

.firstBox-heading {
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  padding: 0 24px 16px 24px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0; }

.firstBox-subheading {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  margin: 0 24px 32px 24px;
  color: #6B7177; }

.whatsapp-button {
  background: #25D366;
  border-radius: 3px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 12px 0;
  margin: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center; }

.firstBox-secondHeading {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  padding: 40px 24px 16px 24px;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  margin: 0px; }

.firstBox-secondSubheading {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  padding: 0 24px 40px 24px;
  text-align: center;
  margin: 0px;
  color: #00A8F4; }

.firstBox-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
  padding: 40px 24px;
  text-align: center; }

.coverImage {
  height: 260px; }

.secondBox {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: #FBF7ED; }

.secondBox-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.6);
  margin: 0px; }

.secondBox-subheading {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  padding: 24px 0 16px 0;
  color: rgba(0, 0, 0, 0.87);
  margin: 0px; }

.secondBox-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  margin: 0px;
  color: rgba(0, 0, 0, 0.6); }

.thirdBox {
  padding: 32px 24px 0 24px;
  display: flex;
  flex-direction: column; }

.thirdBox-item {
  display: flex;
  padding-bottom: 40px; }

.thirdBox-right {
  padding-left: 24px;
  display: flex;
  flex-direction: column; }

.thirdBox-right-heading {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 24px;
  margin: 0;
  padding: 0 0 8px 0;
  color: #212326; }

.thirdBox-right-subheading {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
  color: #6B7177; }

.button-background {
  background: #FFFFFF;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.24);
  padding: 8px 16px;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1; }

.button-fixed {
  background: #25D366;
  border-radius: 3px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }

@media (max-width: 360px) {
  .coverImage {
    height: 230px; } }

@media (max-width: 340px) {
  .coverImage {
    height: 210px; } }

.referral {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  position: relative; }

.card {
  background: linear-gradient(180deg, #f6f9fe 0%, rgba(255, 255, 255, 0) 100%);
  width: 90%;
  position: absolute;
  top: 80%;
  z-index: 1;
  margin-bottom: 74px;
  background: #e5e5e5; }

.firstCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background: #ffffff;
  margin-bottom: -1px; }

.firstcard-blueText {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height, or 150% */
  margin: 0;
  color: #004cbe; }

.firstcard-blackText {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;
  /* or 150% */
  margin: 0;
  color: #121212; }

.firstcard-button-div {
  border-bottom: 1px solid #e5e5e5;
  width: 100%; }

.firstcard-referBtn {
  background: #009AE0;
  border-radius: 4px;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
  padding: 16px 0;
  width: 100%; }

.firstCard-validity {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  padding-top: 8px; }

.secondCard {
  display: flex;
  padding: 16px;
  flex-direction: column;
  background: #ffffff; }

.secondCard-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height, or 150% */
  color: #121212; }

.secondCard-div {
  display: flex; }

.secondCard-div-heading {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */
  color: rgba(0, 0, 0, 0.87); }

.thirdCard {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin: 24px 0; }

.thirdCard-heading {
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 4px; }

.thirdCard-heading-head {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  margin: 0;
  color: rgba(0, 0, 0, 0.87); }

.thirdCard-heading-tc {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  outline: none;
  border: none;
  background: #fff;
  color: #009ae0; }

.thirdCard-content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  padding-left: 16px;
  color: rgba(0, 0, 0, 0.87); }

.thirdCard-footer {
  border-top: 1px solid #e5e5e5;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  padding: 16px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 4px; }

.fourthCard {
  margin: 24px 0; }

.fifthCard {
  margin-bottom: 100px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12); }

.fifthCard-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0px;
  border-bottom: 1px solid #E5E5E5; }

.fifthCard-footer {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  /* or 127% */
  padding: 16px;
  margin: 0px;
  border-top: 1px solid #E5E5E5;
  color: rgba(0, 0, 0, 0.6); }

.share-button-div {
  justify-content: center;
  z-index: 1;
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.24);
  padding: 16px;
  font-family: SF Pro Text; }

.share-button {
  background: #25d366;
  border-radius: 3px;
  padding: 11px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  width: 90%;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center; }

.ReferralModal__Modal {
  display: -webkit-flexbox;
  display: flex;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6); }
  .ReferralModal__Modal--ModalContainer {
    background: #ffffff;
    border-radius: 12px;
    margin: auto;
    width: 100%; }
    .ReferralModal__Modal--ModalContainer__Header {
      padding: 2.4rem 2.4rem 2.4rem 3.2rem;
      border-bottom: 1px solid #e5e5e5; }
      .ReferralModal__Modal--ModalContainer__Header--r1 {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-style: normal;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 2.4rem;
        color: rgba(0, 0, 0, 0.87); }
      .ReferralModal__Modal--ModalContainer__Header--r1 label {
        margin-top: 2rem; }
      .ReferralModal__Modal--ModalContainer__Header--r2 {
        margin-top: 1.2rem;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.6);
        padding-right: 2rem; }
    .ReferralModal__Modal--ModalContainer__Body {
      padding: 2.4rem 3.2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%; }
      .ReferralModal__Modal--ModalContainer__Body--r1 {
        width: 100%; }
        .ReferralModal__Modal--ModalContainer__Body--r1__MobileHead {
          font-style: normal;
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: #2d2d45;
          width: 100%;
          padding-bottom: 1rem; }
        .ReferralModal__Modal--ModalContainer__Body--r1__MobileNumber {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 100%;
          border: 0.1rem solid #e5e5e5;
          box-sizing: border-box;
          border-radius: 0.4rem;
          margin-bottom: 2.4rem; }
        .ReferralModal__Modal--ModalContainer__Body--r1__MobileNumber input {
          width: 100%;
          padding: 1.2rem 0;
          border: none;
          margin-left: 1rem; }
        .ReferralModal__Modal--ModalContainer__Body--r1__MobileError {
          margin-top: -2rem;
          color: red;
          font-size: 1.2rem;
          z-index: 100;
          margin-bottom: 0.8rem;
          width: 100%; }
      .ReferralModal__Modal--ModalContainer__Body--r2 {
        width: 100%; }
        .ReferralModal__Modal--ModalContainer__Body--r2__Button {
          width: 100%;
          padding: 1.3rem 0;
          border-radius: 4px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #ffffff; }
  .ReferralModal__Modal--ModalContainerS {
    background: #ffffff;
    border-radius: 12px;
    margin: auto;
    width: 100%;
    padding: 2.4rem; }
    .ReferralModal__Modal--ModalContainerS__Head {
      display: -webkit-flexbox;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
    .ReferralModal__Modal--ModalContainerS__Thanks {
      width: 100%;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 2.8rem;
      line-height: 2.4rem;
      color: rgba(0, 0, 0, 0.87);
      margin-top: 2.4rem; }
    .ReferralModal__Modal--ModalContainerS__SMS {
      font-style: normal;
      font-weight: normal;
      font-size: 1.8rem;
      line-height: 2.1rem;
      text-align: center;
      letter-spacing: 0.1rem;
      color: rgba(0, 0, 0, 0.6);
      margin-top: 2.4rem; }
    .ReferralModal__Modal--ModalContainerS__Button--btn {
      width: 100%;
      border: 1px solid #009ae0;
      box-sizing: border-box;
      border-radius: 4px;
      margin-top: 2.4rem;
      background: #ffffff;
      padding: 1.3rem;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #009ae0; }

.IN {
  border-right: 1px solid #e5e5e5;
  padding: 0 1rem; }

input {
  color: rgba(0, 0, 0, 0.6); }

input:focus {
  outline: none; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

.Footer {
  background-color: #0E3A62;
  padding: 6rem 15rem;
  color: #ffffff;
  display: flex !important;
  flex-direction: column !important; }
  @media only screen and (max-width: 64em) {
    .Footer {
      padding: 4rem 12rem; } }
  @media only screen and (max-width: 56.25em) {
    .Footer {
      padding: 2rem 12rem; } }
  @media only screen and (max-width: 37.5em) {
    .Footer {
      padding: 6rem 2.4rem; } }
  .Footer__content {
    padding-bottom: 4.8rem; }
    @media only screen and (max-width: 37.5em) {
      .Footer__content {
        text-align: center; } }
    .Footer__content--title {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2rem;
      margin-bottom: 2.2rem; }
      @media only screen and (max-width: 37.5em) {
        .Footer__content--title {
          margin-bottom: 1.7rem; } }
    .Footer__content--company--item {
      margin-bottom: 0.8rem;
      color: #FFFFFF;
      text-decoration: none; }
    .Footer__content--contact {
      display: flex !important;
      flex-direction: column !important; }
      @media only screen and (max-width: 37.5em) {
        .Footer__content--contact {
          margin-top: 2rem; } }
    .Footer__content--address {
      font-size: 1.4rem; }
    .Footer__content--phone {
      margin-top: 1.6rem;
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 500; }
      .Footer__content--phone--img {
        margin-right: 1rem; }
      @media only screen and (max-width: 37.5em) {
        .Footer__content--phone--extra {
          text-align: center; } }
    .Footer__content--reach {
      text-align: left; }
      @media only screen and (max-width: 48em) {
        .Footer__content--reach {
          text-align: center; } }
      @media only screen and (max-width: 37.5em) {
        .Footer__content--reach {
          margin-top: 2rem; } }
    .Footer__content--contacts {
      margin-left: 2.2rem; }
      @media only screen and (max-width: 37.5em) {
        .Footer__content--contacts {
          margin-left: 0rem; } }
    .Footer__content--contacts > * {
      margin-left: 1.7rem; }
      @media only screen and (max-width: 56.25em) {
        .Footer__content--contacts > * {
          margin-left: 1rem; } }
      @media only screen and (max-width: 37.5em) {
        .Footer__content--contacts > * {
          margin-right: 0.5rem; } }
  .Footer__copyrights {
    padding-top: 3.2rem;
    border-top: 1px solid #FFFFFF;
    text-align: center; }

@media only screen and (max-width: 37.5em) {
  .classplusFooter {
    display: none; } }

.footer-classroom {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin: auto; }

@media (max-width: 1000px) {
  .footer-classroom {
    flex-direction: column; } }

.navigation {
  padding: 15px 7%;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15), 0px 0px 0px rgba(63, 63, 68, 0.05);
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100; }
  @media only screen and (max-width: 80em) {
    .navigation {
      padding: 15px 10rem; } }
  @media only screen and (max-width: 64em) {
    .navigation {
      padding: 15px 8rem; } }
  @media only screen and (max-width: 64em) {
    .navigation {
      padding: 15px 6rem; } }
  @media only screen and (max-width: 48em) {
    .navigation {
      padding: 15px 7rem; } }
  @media only screen and (max-width: 37.5em) {
    .navigation {
      padding: 15px 2rem; } }
  .navigation__logo--img {
    width: 180px; }
    @media only screen and (max-width: 37.5em) {
      .navigation__logo--img {
        width: 101px; } }

.scrollButton {
  border-radius: 50px;
  font-weight: 600;
  padding: 1rem 3.1rem; }

@media (max-width: 1000px) {
  .navigation__logo--img {
    width: 196px;
    height: 40px; } }

@media (max-width: 350px) {
  .navigation__logo--img {
    width: 140px; } }


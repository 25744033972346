/* .Container {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.Container ul {
  list-style-type: none;
  display: flex;
}

.Container img {
  width: 100%;
  height: 100%;
} */

.Container {
  position: relative;
  /* overflow: hidden; */
  /* height: 6rem; */
  /* margin: 20px auto 0 auto; */
  /* border-radius: 4px; */
}

.Container ul {
  position: relative;
  display: flex;
  margin: 0;
  padding: 0;
  /* height: 200px; */
  list-style: none;
  -webkit-transition: transform 0.5s ease-in-out;
  -moz-transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  margin-top: 16px;
}

.Container ul li {
  position: relative;
  display: flex;
  float: left;
  flex-shrink: 0;
  padding: 0;
  /* width: 300px;
  height: 200px; */
  /* background: #333944; */
  background: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  /* height: 10px; */

  /* line-height: 200px; */
}

/* .Container ul li p {
  margin: 0px;
  height: 100%;
} */

.Container button {
  position: absolute;
  top: 30px;
  cursor: default;
  height: 100%;
  width: 55px;
  background: none;
  color: white;
  border: none;
  outline: none;
  display: block;
  -webkit-tap-highlight-color: transparent;
}

.roundButton {
  height: 4rem;
  align-items: center;
  margin-top: 16px;
  display: flex;
  width: 4rem;
  justify-content: center;
  cursor: pointer;
  background: white;
  opacity: 0.9;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(172, 173, 175, 0.2),
    0px 3px 4px rgba(172, 173, 175, 0.12), 0px 2px 4px rgba(172, 173, 175, 0.14);
}

.disabledRound {
  box-shadow: none !important;
  opacity: 0.9;
  /* cursor: not-allowed; */
}

.disabledRound:hover {
  opacity: 0.9 !important;
}

.roundButton:hover {
  -webkit-box-shadow: 7px 13px 13px -9px rgba(0, 0, 0, 0.33);
  opacity: 1;
  -moz-box-shadow: 7px 13px 13px -9px rgba(0, 0, 0, 0.33);
  box-shadow: 7px 13px 13px -9px rgba(0, 0, 0, 0.33);
}

.roundButton:active {
  box-shadow: 0px 1px 5px rgba(172, 173, 175, 0.2),
    0px 3px 4px rgba(172, 173, 175, 0.12), 0px 2px 4px rgba(172, 173, 175, 0.14);
  -webkit-box-shadow: 0px 1px 5px rgba(172, 173, 175, 0.2),
    0px 3px 4px rgba(172, 173, 175, 0.12), 0px 2px 4px rgba(172, 173, 175, 0.14);
  -moz-box-shadow: 0px 1px 5px rgba(172, 173, 175, 0.2),
    0px 3px 4px rgba(172, 173, 175, 0.12), 0px 2px 4px rgba(172, 173, 175, 0.14);
  opacity: 1;
}

.Container button.Left {
  /* background: linear-gradient(to right, #AAAAAA77, #AAAAAA00);
  background: linear-gradient(to left, transparent 0%, rgba(0, 0, 0, 0.25) 100%); */
}

.Left {
  cursor: pointer;
}

.Right {
  cursor: pointer;
}

.Container button.Right {
  /* background: linear-gradient(to right, #AAAAAA00, #AAAAAA77);
  background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.25) 100%);
  background: grey; */
}

.Container button.Left {
  left: 2rem;
  width: auto;
}

.Container button.Right {
  right: 2rem;
  width: auto;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */

/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'angle-left';
    src: url('./font/angle-left.svg?23394832#angle-left') format('svg');
  }
}
*/

.IconRight:before,
.IconLeft:before {
  font-family: "icon-fonts";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  /* width: 1em; */
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.IconRight:before {
  content: "\f105";
}

/* '' */

.IconLeft:before {
  content: "\f104";
}

/* '' */

/*@media(max-width: 500px) {
  .Container button {
    display: block;
    -webkit-tap-highlight-color: transparent;
  }
}*/
.dotNavigations {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
}

.dots {
  cursor: pointer;
  background: rgba(0, 154, 224, 0.4);
  display: flex;
  height: 10px;
  margin: 0 4px;
  width: 10px;
  border-radius: 50%;
}

.activeDot {
  background: #009ae0;
}

.carouselHeader {
  padding-left: 1rem;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  /* text-transform: uppercase; */
  color: rgba(0, 0, 0, 0.87);
}

.carouselUl>li {
  margin: 0 1rem;
}

.carouselUl>li:first-child {
  padding-left: 0;
  margin-left: 0;
}

.carouselUl>li:last-child {
  padding-left: 0;
  margin-right: 0;
}

@media(max-width:1000px) {
  .Container button {
    top: 70px;

  }

  .Container button.Left {
    left: 1rem;
    width: auto;
  }

  .Container button.Right {
    right: 1rem;
    width: auto;
  }
}
.navigation {

    padding: 15px 7%;
    height: 70px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-primary-white;
    box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
        0px 0px 0px rgba(63, 63, 68, 0.05);
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 100;

    @include respond(tab-land) {
        padding: 15px 10rem;
    }

    @include respond(large-screen) {
        padding: 15px 8rem
    }

    @include respond(medium-screen) {
        padding: 15px 6rem;
    }


    @include respond(ipad) {
        padding: 15px 7rem;
    }

    @include respond(phone) {
        padding: 15px 2rem;
    }

    &__logo {
        &--img {
            width: 180px;

            @include respond(phone) {
                width: 101px;
                // height: 3.4rem;


            }
        }


    }
}


.navigation__logo--img {
    // width: 163px;
}

.scrollButton {
    border-radius: 50px;
    font-weight: 600;
    padding: 1rem 3.1rem;
}

@media(max-width:1000px) {
    .navigation__logo--img {
        width: 196px;
        height: 40px
    }
}

@media(max-width:350px) {
    .navigation__logo--img {
        width: 140px;
    }
}
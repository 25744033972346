    .question-box {
        display: flex;
        flex-direction: column;
        // border-top: 1px solid #E5E5E5;
        // border-left: 1px solid #E5E5E5;
        // border-right: 1px solid #E5E5E5;
        width: 100%;
        // margin: 60px auto;

    }

    .question-firstdiv {
        display: flex;
        justify-content: space-between;
        width: 100%;
        // border-bottom: 1px solid #E5E5E5;
        // padding: 24px 32px;
        cursor: pointer;
        outline: none;
    }


    .question-firstline {

        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: #212326;
        margin: 0;
        transition: all 0.4s ease-out;
    }

    .question-secondline {
        white-space: pre-line;

        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        // padding: 24px 32px;

        display: flex;
        align-items: center;
        transition: all 0.4s ease-in;
        color: rgba(0, 0, 0, 0.6);
    }

    @media(max-width:1000px) {
        .question {
            margin: 30px auto
        }

        .question-firstdiv {
            // padding: 16px;
        }


        .question-firstline {
            font-size: 16px;

        }

        .question-secondline {
            font-size: 16px;
            line-height: 32px;
            // padding: 16px;
        }


    }
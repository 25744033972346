.landing {
    // background-image: url("../../images/background.svg");
    background-position: center;
    margin-top: 7rem;
    padding: 6.5rem 0 0 0;
    background: linear-gradient(180.28deg, #E8F4FF -10.81%, #FFFFFF 86.3%);
    height: 80vh;

    @include respond(phone) {
        padding: 0;
        margin-top: 6rem
    }

    &__content {
        grid-column: second-start/second-end;
        // margin-left: 48px;
        @include flexColumn;
        align-self: center;
        position: relative;
        color: #fff;
        height: 100%;
        // font-family: 'Nunito Sans', sans-serif;
        //   animation: easyLeft 0.3s ease-in;


        @include respond(phone) {
            //     height: 20rem;
            text-align: center;

        }

        &--main {
            font-size: $font-size-heading-mega;
            font-weight: $font-weight-bold;
            line-height: 6rem;
            //display: none;


            @include respond(phone) {
                margin-top: 3rem;
                font-size: $font-size-heading-small;
                padding: 2.7rem;
                line-height: 4rem;
                display: block;
            }

            &--corona {

                font-size: 6rem;
                display: flex;

                @include respond(phone) {
                    font-size: 7rem;
                    margin-top: 8rem;
                    padding: 0;
                    @include flexCenter;
                    text-align: center;
                    flex-direction: column;
                }
            }

            &--corona>div {
                @include respond(phone) {
                    margin-bottom: 2rem;
                }
            }

        }

        &--sub {
            // margin-top: 3.4rem;
            font-size: 2.4rem;
            font-weight: $font-weight-medium;
            line-height: 3rem;
            // display: none;



            @include respond(phone) {
                // display: none;
                font-size: 2rem;
                margin-top: 0rem;
                padding: 0 2.2rem 2.7rem;
                display: block;
            }

            &--corona {
                font-size: 3.2rem !important;
                line-height: 4rem !important;

                @include respond(phone) {
                    margin-top: 4rem;
                    padding: 0 2rem;
                }
            }

            &--hashtag {
                margin-top: 1rem !important;
                font-weight: $font-weight-light;

                @include respond(phone) {
                    font-size: 2.5rem;

                }
            }

            &--prompt {
                margin-top: 5rem !important;
                font-weight: $font-weight-light;
                font-size: 4rem;

                &--2 {

                    margin-bottom: 3rem;


                }

                @include respond(phone) {
                    margin-top: 2rem !important;
                    padding: 0;
                    font-size: 3.2rem;
                    font-weight: $font-weight-bold;
                }
            }
        }
    }

    &__form {
        background-color: $color-primary-white;
        padding: 32px 0px 28px 0px;
        width: 50rem;
        border-radius: 4px;
        margin-left: 12rem;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        //  animation: easyRight 0.3s ease-in;



        @include respond(tab-land) {
            margin-left: 2rem;
        }

        @include respond(medium-screen) {
            margin-left: 7rem;
        }

        @include respond(tab-port) {
            padding: 2.7rem 3.2rem 2.7rem 2.2rem;
        }

        @include respond(ipad) {
            padding: 2.7rem 4.2rem 2.7rem 1.2rem;
        }

        @include respond(phone) {
            margin: 0;
            //min-width: 90%;
            text-align: left;
            max-width: unset;
            padding: 2.7rem 0;
        }

        &--header {
            &--heading {
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 29px;
                padding: 24px 32px 0px 32px;
                // border-bottom: 1px solid #E5E5E5;
                border-top: 1px solid #E5E5E5;



                @include respond(tab-land) {
                    font-size: 24px;
                }

                @include respond (ipad) {
                    line-height: 24px;
                }

                @include respond (phone) {
                    font-size: 18px;

                }


            }

            &--content {

                font-size: $font-size-small;
                line-height: 2.4rem;
                font-weight: $font-weight-medium;
                padding-top: 10px;

                @include respond (ipad) {
                    margin-top: 2.7rem;
                }

                @include respond (phone) {
                    margin-top: 1rem;
                }

            }
        }


    }
}

.web-down-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 48px
}

.mobile-down-arrow {
    display: none;
    align-items: center;
    justify-content: center;
}

.topDivSubheading {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    padding-top: 24px;
    margin: 0 0 4px 0;
    color: #5E6D89;

}

.landing-top-flex {
    background: #ffffff;
    display: flex;
    flex-direction: column;

}

.landing-embrace {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 80px 8px;
    width: 50%;
    margin: auto;
}

.landing-embrace-heading {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 38px;
    text-align: center;

    color: #212326;

}

.landing-embrace-subheading {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    text-align: center;

    color: #6B7177;

}

.form-basic {

    height: fit-content;
    box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
        0px 0px 0px rgba(63, 63, 68, 0.05);


}

.topDivHeading {
    font-size: 40px;
    line-height: 52px;
    color: #000000;
}

.landing-topImage {
    width: 379px;
    margin-top: 60px;
}




@media(max-width:1000px) {
    .landing {
        height: 100%
    }


    .web-down-arrow {
        display: none;
    }

    .mobile-down-arrow {
        display: none;
    }

    .topDiv-padding {
        padding: 3rem 2rem 40px 2rem;
        text-align: left;
    }

    .topDivHeading {
        padding: 0 0 2rem 0;
        font-size: 32px;
        display: block;
        flex-direction: column;
        line-height: 40px;
    }

    .topDivSubheading {
        font-size: 16px;
        line-height: 24px;
        padding-top: 0px;
        margin-bottom: 0;
    }

    .indiaFlag {
        bottom: 13px;
    }

    .landing-embrace {
        padding-top: 60px;
        width: 90%;
    }

    .landing-topImage {
        width: 100%;
        margin-top: 32px;
        // padding: 0 16px;
    }

    .landing__content {
        margin-left: 0px;
        // height: 80vh
    }



}


// @media(min-width:2000px) {
//     .landing__content {
//         padding-top: 6rem
//     }
// }


.landing__Image {
    width: 55rem;
    height: 45rem;
    align-self: center;

    @include respond(phone) {
        // width:30rem;
        // height: 30rem;
        display: none;

    }
}

.instantFix {
    width: fit-content;
    font-size: 2.5rem;

    @include respond(phone) {
        align-self: center;

        font-size: 2rem;
    }
}


@media only screen and (min-width:2000px) and (max-width:2700px) {
    .landing {
        padding: 0;
    }

    .landing__form {
        width: 46rem;
        margin-left: 16px;
    }
}

@media only screen and (min-width: 1440px) {
    // .landing__form {
    //     max-height: 45rem;
    // }
}


@media only screen and (min-width: 1925px) {
    .landing__form {
        margin-top: 6rem;
    }
}

@media only screen and (max-width: 1925px) {
    .landing__form {
        margin-top: 6rem;
    }
}

@media only screen and (max-width: 1875px) {
    .landing__form {
        margin-top: 4rem;
    }
}

@media only screen and (max-width: 1825px) {
    .landing__form {
        margin-top: 2rem;
    }
}


@media only screen and (width: 1800px) {
    .landing__form {
        margin-top: 1rem;
    }
}

@media only screen and (max-width: 1799px) {
    .landing__form {
        margin-top: 13rem;
    }
}



@media only screen and (max-width: 1775px) {
    .landing__form {
        margin-top: 12rem;
    }
}

@media only screen and (max-width: 1750px) {
    .landing__form {
        margin-top: 11rem;
    }
}


@media only screen and (max-width: 1725px) {
    .landing__form {
        margin-top: 10rem;
    }
}


@media only screen and (max-width: 1700px) {
    .landing__form {
        margin-top: 9rem;
    }
}

@media only screen and (max-width: 1675px) {
    .landing__form {
        margin-top: 8rem;
    }
}

@media only screen and (max-width: 1650px) {
    .landing__form {
        margin-top: 7rem;
    }
}

@media only screen and (max-width: 1625px) {
    .landing__form {
        margin-top: 6rem;
    }
}

@media only screen and (max-width: 1600px) {
    .landing__form {
        margin-top: 5rem;
    }
}


@media only screen and (max-width: 1575px) {
    .landing__form {
        margin-top: 4rem;
    }
}


@media only screen and (max-width: 1550px) {
    .landing__form {
        margin-top: 3rem;
    }
}


@media only screen and (max-width: 1525px) {
    .landing__form {
        margin-top: 2rem;
    }
}

@media only screen and (max-width: 1500px) {
    .landing__form {
        margin-top: 1rem;
    }
}

@media(max-width:1200px) {
    .landing__form {
        width: auto;
    }
}


@media(max-width:355px) {
    .landing__form--header--heading {
        font-size: 15px;
    }
}

.line-1 {
    white-space: nowrap;
    overflow: hidden;
}

/* Animation */
.anim-typewriter {
    animation: typewriter 4s steps(44) 1s 1 normal both,
        blinkTextCursor 500ms steps(44) infinite normal;
}

@keyframes typewriter {
    from {
        width: 0;
    }

    to {
        width: 24em;
    }
}

@keyframes blinkTextCursor {
    from {
        border-right-color: rgba(255, 255, 255, .75);
    }

    to {
        border-right-color: transparent;
    }
}

.landing__content--sub--prompt--2 {
    @include respond(phone) {
        font-size: 2.5rem
    }
}
.ReferralModal {
  &__Modal {
    display: -webkit-flexbox;
    display: flex;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);

    &--ModalContainer {
      background: #ffffff;
      border-radius: 12px;
      margin: auto;
      width: 100%;

      &__Header {
        padding: 2.4rem 2.4rem 2.4rem 3.2rem;
        border-bottom: 1px solid #e5e5e5;
        &--r1 {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-style: normal;
          font-weight: 500;
          font-size: 2.4rem;
          line-height: 2.4rem;
          color: rgba(0, 0, 0, 0.87);
        }
        &--r1 label {
          margin-top: 2rem;
        }
        &--r2 {
          margin-top: 1.2rem;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: rgba(0, 0, 0, 0.6);
          padding-right: 2rem;
        }
      }

      &__Body {
        padding: 2.4rem 3.2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        &--r1 {
          width: 100%;
          &__MobileHead {
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: #2d2d45;
            width: 100%;
            padding-bottom: 1rem;
          }
          &__MobileNumber {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            border: 0.1rem solid #e5e5e5;
            box-sizing: border-box;
            border-radius: 0.4rem;
            margin-bottom: 2.4rem;
          }
          &__MobileNumber input {
            width: 100%;
            padding: 1.2rem 0;
            border: none;
            margin-left: 1rem;
          }
          &__MobileError {
            margin-top: -2rem;
            color: red;
            font-size: 1.2rem;
            z-index: 100;
            margin-bottom: 0.8rem;
            width: 100%;
          }
        }

        &--r2 {
          width: 100%;

          &__Button {
            width: 100%;
            padding: 1.3rem 0;
            // background: #009ae0;
            border-radius: 4px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
          }
        }
      }
    }

    &--ModalContainerS {
      background: #ffffff;
      border-radius: 12px;
      margin: auto;
      width: 100%;
      padding: 2.4rem;

      &__Head {
        display: -webkit-flexbox;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      &__Thanks {
        width: 100%;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 2.8rem;
        line-height: 2.4rem;
        color: rgba(0, 0, 0, 0.87);
        margin-top: 2.4rem;
      }
      &__SMS {
        font-style: normal;
        font-weight: normal;
        font-size: 1.8rem;
        line-height: 2.1rem;
        text-align: center;
        letter-spacing: 0.1rem;
        color: rgba(0, 0, 0, 0.6);
        margin-top: 2.4rem;
      }

      &__Button {
        &--btn {
          width: 100%;
          border: 1px solid #009ae0;
          box-sizing: border-box;
          border-radius: 4px;
          margin-top: 2.4rem;
          background: #ffffff;
          padding: 1.3rem;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #009ae0;
        }
      }
    }
  }
}

.IN {
  border-right: 1px solid #e5e5e5;
  padding: 0 1rem;
}

input {
  color: rgba(0, 0, 0, 0.6);
}

input:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.pointer-topDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: rgba(0, 154, 224, 0.04);
    padding: 60px 0;
    background: linear-gradient(180.28deg, #E8F4FF -10.81%, #FFFFFF 86.3%);
}

.flex-class-column {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.flex-class-row {
    display: flex;
    margin: 12px 0;

}

.pointer-firstbox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

}

.pointer-heading {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 48px;
    color: #212326;
    margin-bottom: 16px;
}

.pointer-content {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    color: #6B7177;


}

.pointer-point {
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    color: #6B7177;
}


.pointer-secondbox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding-top: 66px;

}

.pointer-list-div {}

@media(max-width:1000px) {
    .pointer-firstbox {
        flex-direction: column;
    }

    .pointer-secondbox {
        flex-direction: column-reverse;


    }

    .flex-class-column {
        width: 100%;
        text-align: center;
        padding: 0 16px;
    }

    .flex-class-row {}

    .pointer-heading {
        font-size: 32px;
        line-height: 48px;
    }

    .pointer-content {
        font-size: 16px;
        line-height: 19px;
    }

    .pointer-list-div {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 87%;
        justify-content: center;
        margin: auto;
    }
}

@media(max-width:340px) {
    .pointer-list-div {
        width: 100%;
    }
}